export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'de-CH',
  messages: {
    de: {
      welcome: 'Willkommen',
    },
    fr: {
      welcome: 'Bienvenue',
    },
  },
}));
